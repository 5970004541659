import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Quote from "../../components/text-decorations/quote";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Lately, I've been contributing to `}<a parentName="p" {...{
        "href": "https://github.com/pyscript/pyscript"
      }}>{`Pyscript`}</a>{` in my free time. Most of my contributions have been tackling the `}<a parentName="p" {...{
        "href": "https://github.com/pyscript/pyscript/issues/679"
      }}>{`epic to improve test coverage`}</a>{`. I also find it helpful to start looking at or creating tests when getting started on a new project. `}</p>
    <p>{`Pyscript has a lot of `}<a parentName="p" {...{
        "href": "https://pyscript.net/examples/"
      }}>{`examples`}</a>{` in the repository, and the tests use these for integration tests. One of the examples uses NumPy and Matplotlib to generate a graph (`}<a parentName="p" {...{
        "href": "https://pyscript.net/examples/matplotlib.html"
      }}>{`see the example`}</a>{`). Initially, I had no idea how to test this since the page renders the graph in an `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag.`}</p>
    <p>{`Luckily, `}<a parentName="p" {...{
        "href": "https://github.com/madhur-tandon"
      }}>{`Madhur Tandon`}</a>{` pointed me in the right direction. The suggestion:`}</p>
    <Quote mdxType="Quote">
  <p>One approach could be to compare the underlying numpy data for the image rendered through the canvas along with a reference image uploaded to the repository.</p>
  <a href="https://github.com/pyscript/pyscript/pull/773#discussion_r974138341">Github Review</a>
    </Quote>
    <p>{`In this article, I will describe how I wrote the test to confirm that the two images are the same by using NumPy. Although, all credits go to Madhur and the code you can `}<a parentName="p" {...{
        "href": "https://github.com/pyodide/matplotlib-pyodide/blob/fbfe472e6acaf081855d02c3a4e52d6cb6b7b03d/matplotlib_pyodide/html5_canvas_backend.py#L72"
      }}>{`find in the matplotlib_pyodide`}</a>{` package.`}</p>
    <h2 {...{
      "id": "the-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-requirements",
        "aria-label": "the requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Requirements`}</h2>
    <p>{`Here's all we need to test that the two images are the same:`}</p>
    <ul>
      <li parentName="ul">{`An image to use as a reference`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://python-pillow.org/"
        }}>{`Pillow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://numpy.org/"
        }}>{`Numpy`}</a></li>
    </ul>
    <p>{`We will use Pillow to create the image from bytes and then NumPy to confirm that both images are identical. This`}</p>
    <p>{`We need an image to use as a reference because the matplotlib example generates the graph each time the example is run. We want to ensure that if breaking changes are introduced that cause the image to be different, we will know immediately by the test failure.`}</p>
    <h2 {...{
      "id": "the-test",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-test",
        "aria-label": "the test permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Test`}</h2>
    <p>{`The graph produced by Matplotlib is being added to the page by passing its base64 encoded string as the source. Since we have the base64 encoded string in the source of the image, we can get the image and then read its `}<inlineCode parentName="p">{`src`}</inlineCode>{` attribute.`}</p>
    <p>{`Pyscript uses `}<a parentName="p" {...{
        "href": "https://playwright.dev/python/"
      }}>{`Playwright`}</a>{` for the integration tests, so we can use playwright to fetch the image source. Also, this page contains a single image, so we don't need to worry about being specific about which to grab.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# First get image from the page and then get the src details
img_src = self.page.wait_for_selector("img").get_attribute("src")
# Replace anything that is not the base64 string
img_src = img_src.replace("data:image/png;charset=utf-8;base64,", "")
# Finally, decode the base64 string to get the image bytes
img_bytes = base64.b64decode(img_src)
`}</code></pre>
    <p>{`We need to recreate the image from its bytes and generate a NumPy array from it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import io
import numpy as np
from PIL import Image
# Recreate image using pillow
img = Image.open(io.BytesIO(img_bytes))
# Generate the numpy array
img_data = np.asarray(img)
`}</code></pre>
    <h3 {...{
      "id": "create-the-numpy-array-from-the-reference-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-the-numpy-array-from-the-reference-image",
        "aria-label": "create the numpy array from the reference image permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create the NumPy array from the reference image`}</h3>
    <p>{`Now we need to do the same for our reference image. Since we have the image stored, we can open it with Pillow and generate the NumPy array. If you are unfamiliar with Pillow, this library allows you to open images directly, so you don't need to open them as bytes first.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import os
dir = os.path.dirname(__file__)
with Image.open(os.path.join(dir, "test_assets", "tripcolor.png")) as image:
  ref_data = np.asarray(image)
`}</code></pre>
    <h2 {...{
      "id": "comparing-the-two-images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#comparing-the-two-images",
        "aria-label": "comparing the two images permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comparing the two images`}</h2>
    <p>{`We now have the representation of the two images as a Numpy array. We can compare both images by subtracting both arrays and get the mean. If both images are the same, then the result of the subtraction will be an array filled with zeros, and the mean returned should be 0.0`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`deviation = np.mean(np.abs(img_data - ref_data))
# Confirm that both are the same image - should return 0.0
assert deviation == 0.0
`}</code></pre>
    <p>{`That's all there is to it. Again let me reiterate that this code came from the `}<a parentName="p" {...{
        "href": "https://github.com/pyodide/pyodide/blob/b0dc19e4a169c9dcf97e21fe0ac2c635a0900f13/packages/matplotlib/test_matplotlib.py#L72"
      }}>{`pyodide matplotlib package`}</a>{`.`}</p>
    <h2 {...{
      "id": "the-whole-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-whole-code",
        "aria-label": "the whole code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The whole code`}</h2>
    <p>{`Here's the whole code in case you need it - note that it contains some testing machinery that Pyscript uses.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import io
import numpy as np
from PIL import Image


def test_matplotlib(self):
    self.goto("examples/matplotlib.html")
    self.wait_for_pyscript()
    assert self.page.title() == "Matplotlib"
    wait_for_render(self.page, "*", "<img src=['\\"]data:image")
    # The image is being rended using base64, lets fetch its source
    # and replace everything but the actual base64 string.\\
    img_src = self.page.wait_for_selector("img").get_attribute("src").replace("data:image/png;charset=utf-8;base64,", "")
    # Finally, let's  get the np array from the previous data
    img_data = np.asarray(Image.open(io.BytesIO(base64.b64decode(img_src))))
    with Image.open(
        os.path.join(os.path.dirname(__file__), "test_assets", "tripcolor.png"),
    ) as image:
        ref_data = np.asarray(image)
    # Now that we have both images data as a numpy array
    # let's confirm that they are the same
    deviation = np.mean(np.abs(img_data - ref_data))
    assert deviation == 0.0
`}</code></pre>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pyscript/pyscript/pull/773"
        }}>{`Pyscript testing PR`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pyscript/pyscript/pull/773#discussion_r974138341"
        }}>{`Madhur Tandon suggestion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pyodide/matplotlib-pyodide/blob/fbfe472e6acaf081855d02c3a4e52d6cb6b7b03d/matplotlib_pyodide/html5_canvas_backend.py#L72"
        }}>{`Pyodide Matplotlib get image pixel data code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pyodide/pyodide/blob/b0dc19e4a169c9dcf97e21fe0ac2c635a0900f13/packages/matplotlib/test_matplotlib.py#L72"
        }}>{`Pyodide Matplotlib image comparison code`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      